.footerTableTotal {
  text-align: end;
  padding: 1.4rem 1rem;
  border-top: 1px solid #dee2e6;
}

.customList {
  list-style: none;
  padding: 0;
}

.customListRow {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #F0F0F0;
}