.p-paginator .p-paginator-current {
  margin-right: auto;
  font-size: 12px;
  color: #000000;
  border: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: transparent;
  border: none;
  color: #000000;
}
