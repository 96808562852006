@import "src/layout/design/colors";

.container {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  background: $colorVariablesBackgroundColor;
  opacity: 0.8;

  display: flex;
  align-items: center;
  justify-content: center;
}

.imageScale {
  animation: scale 2000ms ease-in infinite alternate;
}

@keyframes scale {
  to {
    transform: scale(1.2);
  }
}
