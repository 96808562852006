@import '../../../layout/design/colors.scss';

#popup_menu > ul > li:last-child > a > span.p-menuitem-text {
  color: $colorFills7RedRed700;
}

#popup_menu > ul > li:last-child > a > span.p-menuitem-icon.pi.pi-times-circle {
  color: $colorFills7RedRed700;
}

#popup_menu_black > ul > li:last-child > a > span.p-menuitem-text {
  color: $colorFills1BasicBasic600;
}

#popup_menu_black > ul > li:last-child > a > span.p-menuitem-icon.pi.pi-times-circle {
  color: $colorFills1BasicBasic600;
}

#subscriptionBilling > div {
  background: none;
  padding: 0;
  margin: 0 !important;
}

#subscriptionBillingFacture > div {
  padding: 0;
}

.terminationDate {
  background-color: #d8d8d8;
  color: #101010;
  border-radius: 3px;
  font-size: 13px;
  margin-left: 8px;
}
