.r-select-value {
    font-family: system-ui;
    font-size: 16px;
    color: #6c757d;
}

.r-invalid {
    .r-select-control {
        border-color: #ff5959 !important;
        box-shadow: 0px 0px 1px #ff5959;
    }
}

.r-indicator-Container {
    display: flex;
    flex: 0.2;
    justify-content: flex-end;
    padding: 8px 8px 8px 0;
    &>div {
        width: 25px;
    }
    &.singleBtn {
        flex: 0.1;
    }
}

.disabled-button {
    .r-select-control {
        background-color: #ffffff;
    }

    .r-select-value * {
        color: #a0a5aa;
    }

    .pi {
        color: #c1c4c8;
    }
}