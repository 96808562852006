.StellairFormBox {
    padding-bottom: 16px;
}

.StellairDialogInfoBox {
    margin: 8px 0;
    padding-bottom: 16px;
    text-align: center;

    & img {
        margin-bottom: 8px;
        width: 100%;
    }

    & span {
        color: #6A6A6A
    }

    border-bottom: 1px solid #D8D8D8;
}

.StellairClientInfoFromNiqola {
    display: flex;
    flex-direction: column;
    margin: 0 8px;

    h3 {
        font-size: 14px;
        margin-bottom: 4px;
    }

    span {
        margin-bottom: 4px;

        &:last-of-type {
            color: #6A6A6A
        }
    }
}

.StellairClientGrid {
    tfoot {
        display: none;
    }
}

.OfferStellairCol {
    display: flex;
    flex-direction: column;

    & span:first-of-type {
        margin-bottom: 4px;
    }
}

.SubmitButton.stellairSwitchDateBtn {
    width: auto;
    border: none;

    &:focus {
        border: none;
        box-shadow: none;
    }
}