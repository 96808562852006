@import 'src/layout/design/colors';

.prestationPr {
  margin: 0;
  font-weight: 700;
  hyphens: auto;
}

.compositionTableRow {
  vertical-align: top;
}

.customChipOptional {
  font-size: 13px;
  border-radius: 3px;
  background-color: $colorFills4BlueBlue100;
  color: $colorVariablesTextColorBasic800_;
}
