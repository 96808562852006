// ods ovveride
.customButtonDownload {
  width: fit-content;
  height: 32px !important;
  padding: 10px 8px !important;
  border: none !important;
  margin-top: 1rem;
  font-family: Arial !important;
  font-size: 12px !important;
  cursor: pointer;
  background: transparent !important;
}

.customButtonResolve {
  padding: 8px 10px !important;
  border-color: rgba(106, 106, 106, 0.24) !important;
}