$colorVariablesTextColorBasic800_: #101010;
$colorVariablesTextColorBasic400_: #6a6a6a;
$colorVariablesPrimaryColor: #9c67cd;
$colorVariablesPrimaryColorTextBasic0_: #ffffff;
$colorVariablesSecondaryColor: #101010;
$colorVariablesSecondaryColorLight: #f0f0f0;
$colorVariablesFocusRing: #b3d1ff;
$colorVariablesMaskBg: #0000008f;
$colorFills1BasicBasic900Black_: #000000;
$colorFills1BasicBasic800: #101010;
$colorFills1BasicBasic700: #181818;
$colorFills1BasicBasic600: #202020;
$colorFills1BasicBasic500: #404040;
$colorFills1BasicBasic400: #6a6a6a;
$colorFills1BasicBasic300: #b8b8b8;
$colorFills1BasicBasic200: #d8d8d8;
$colorFills1BasicBasic100: #f0f0f0;
$colorFills1BasicBasic50: #f8f8f8;
$colorFills1BasicBasic0White_: #ffffff;
$colorFills2SkinSkin100: #fdfbf9;
$colorFills2SkinSkin200: #fcf9f7;
$colorFills2SkinSkin300: #fcf7f4;
$colorFills2SkinSkin400: #f7f2ef;
$colorFills2SkinSkin500: #f2ebe8;
$colorFills2SkinSkin600: #d0b2a9;
$colorFills2SkinSkin700: #ae7e74;
$colorFills2SkinSkin800: #8c504a;
$colorFills2SkinSkin900: #742e2c;
$colorFills3PurplePurple900: #2c1362;
$colorFills3PurplePurple800: #402076;
$colorFills3PurplePurple700: #5b3393;
$colorFills3PurplePurple600: #7a4bb0;
$colorFills3PurplePurple500: #9c67cd;
$colorFills3PurplePurple400: #bd8de1;
$colorFills3PurplePurple300: #d4a8f0;
$colorFills3PurplePurple200: #eecdff;
$colorFills3PurplePurple100: #f5e3fc;
$colorFills5GreenGreen100: #edfdf2;
$colorFills5GreenGreen200: #dcfbe8;
$colorFills5GreenGreen300: #c6f3dc;
$colorFills5GreenGreen400: #b2e8d2;
$colorFills5GreenGreen500: #96d9c4;
$colorFills5GreenGreen600: #6dbaaa;
$colorFills5GreenGreen700: #4b9c92;
$colorFills5GreenGreen800: #2f7d7c;
$colorFills5GreenGreen900: #1c6268;
$colorFills4BlueBlue100: #eaf4ff;
$colorFills4BlueBlue200: #d6e9ff;
$colorFills4BlueBlue300: #c2dcff;
$colorFills4BlueBlue400: #b3d1ff;
$colorFills4BlueBlue500: #9abeff;
$colorFills4BlueBlue600: #7092db;
$colorFills4BlueBlue700: #4d6bb7;
$colorFills4BlueBlue800: #314993;
$colorFills4BlueBlue900: #1d317a;
$colorFills6YellowYellow100: #fff8ec;
$colorFills6YellowYellow200: #ffeed9;
$colorFills6YellowYellow300: #ffe3c6;
$colorFills6YellowYellow400: #ffd8b7;
$colorFills6YellowYellow500: #ffc6a0;
$colorFills6YellowYellow600: #db9774;
$colorFills6YellowYellow700: #b76d50;
$colorFills6YellowYellow800: #934833;
$colorFills6YellowYellow900: #7a2d1e;
$colorFills7RedRed100: #fef5ef;
$colorFills7RedRed200: #fee8df;
$colorFills7RedRed300: #fdd8cf;
$colorFills7RedRed400: #fcc9c2;
$colorFills7RedRed500: #fbb1ae;
$colorFills7RedRed600: #d77f83;
$colorFills7RedRed700: #b45763;
$colorFills7RedRed800: #91374a;
$colorFills7RedRed900: #78213b;
$colorFills8IndigoIndigo900: #2d217a;
$colorFills8IndigoIndigo800: #463893;
$colorFills8IndigoIndigo700: #6958b7;
$colorFills8IndigoIndigo600: #9380db;
$colorFills8IndigoIndigo500: #c2b0ff;
$colorFills8IndigoIndigo400: #d2c3ff;
$colorFills8IndigoIndigo300: #dccfff;
$colorFills8IndigoIndigo200: #e8dfff;
$colorFills8IndigoIndigo100: #f4efff;
$colorTransparentFill1BasicBasic48: #6a6a6a7a;
$colorTransparentFill1BasicBasic40: #6a6a6a66;
$colorTransparentFill1BasicBasic32: #6a6a6a52;
$colorTransparentFill1BasicBasic24: #6a6a6a3d;
$colorTransparentFill1BasicBasic16: #6a6a6a29;
$colorTransparentFill1BasicBasic12: #6a6a6a1f;
$colorTransparentFill1BasicBasic8: #6a6a6a14;
$colorTransparentFill1BasicBasic4: #6a6a6a0a;
$colorVariablesWarningColor: #db9774;
$colorVariablesSuccessColor: #2f7d7c;
$colorVariablesDangerColor: #b45763;
$colorVariablesInfoColor: #4d6bb7;
$colorVariablesPrimaryColorLight: #fbf5fe;
$colorVariablesLinkColor: #9c67cd;
$colorVariablesBackgroundColor: #fcf7f4;
$colorVariablesLegacyPrimaryColor: #3688ff;
$colorVariablesInputTextColor: #495057;
