.layout-wrapper {
    display: flex;
    flex-direction: row;
    .right-layout {
        flex-grow: 1;
        overflow-y: scroll;
        width: 248px;
        height: 100vh;
        position: relative;
        flex-direction: column;
        display: flex;
        .layout-footer {
            background-color: #ececec;
            color: #959695;
            padding: 1rem 3rem ;
        }
    }
}


.p-dialog-mask {
    @media (min-width: 805px) and (max-width: 992px) {
      padding-left: 40px;
      padding-right:40px;
    }
  }