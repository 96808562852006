@import 'src/layout/design/colors.scss';

#clientDetailView > div {
  background: none;
  padding: 8px;
  margin: 0 !important;
}

.clientsDetailsPage {
  & .header {
    margin: 1rem 0 0.6rem 0;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: bold;
  }
  & .subtitle {
    font-family: 'Inter';
    font-size: 1.1rem;
    margin: 1rem 0 0.6rem 0;
  }
  & .item {
    display: flex;
    color: $colorVariablesSecondaryColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    & span:first-of-type {
      display: block;
      width: 12.5rem;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
    }
    & span:not(:first-of-type) {
      display: block;
      font-weight: 400;
    }
    & .detail {
      &:first-of-type {
        padding-top: 0.75rem;
      }
      line-height: 1.25rem;
    }
  }

  & .delivery-address-table {
    border-bottom: 1px solid $colorVariablesSecondaryColorLight;
    & .field {
      vertical-align: top;
      color: $colorFills1BasicBasic700;
      font-family: Arial;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1rem;
    }
  }
  & .title {
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: bold;
  }
}
