@import "layout/design/colors.scss";

.wordBreak {
  font-family: Arial !important;
  font-size: 14px !important;
  word-break: break-word;
  line-height: 16px;
}

.anomalyContainer {
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: $colorFills7RedRed300;
  border: 1px solid $colorFills7RedRed300;
  border-radius: 3px;
  padding: 0.5rem 1rem;
}

.warningContainer {
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: $colorFills6YellowYellow200;
  border: 1px solid $colorFills6YellowYellow300;
  color: $colorFills6YellowYellow900;
  border-radius: 3px;
  padding: 0.5rem 1rem;
}

.listWarning {
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
  margin: 0;
  padding-left: 1rem;
  list-style-type: disc;
}

// ods ovveride
.customButtonResolve {
  width: fit-content !important;
  height: 32px !important;
  padding: 10px 8px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(106, 106, 106, 0.24) !important;
  margin-top: 1rem;
  font-family: Arial !important;
  font-size: 12px !important;
}
