@import 'src/layout/design/colors';

.clientCreateForm {
  & .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: $colorVariablesSecondaryColor;
  }

  & .label {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 0.25rem;
    user-select: none;
  }

  & .p-error {
    display: inline-block !important;
  }

  & .pi-check {
    color: $colorVariablesSuccessColor;
  }

  & .p-inputtext {
    color: $colorVariablesInputTextColor;
  }
}

.tppInfoBox {
  .infoLabel {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    display: block;
  }

  p {
    font-size: 14px;
    margin: 4px 0 0 0;
  }
}