/* Place your extensions here to make migration to a new release easier. */
@import 'layout/_breakpoints';
@import 'include-media/dist/_include-media.scss';
@import 'layout/design/colors.scss';

.layout-main {
  padding: 1rem 3rem;
  background-color: $colorFills2SkinSkin300;
  flex-grow: 1;

  @include media('<tablet') {
    padding: 16px;
  }
}

.p-button {
  &:hover {
    outline: none;
  }
}

.expandButton {
  position: fixed;
  top: 15px;
  left: -15px;
  z-index: 2;

  @include media('>desktop') {
    display: none;
  }

  @include media('>tablet') {
    left: 0;
  }
}

#menuGalaxie {
  .p-menuitem-text {
    font-family: Arial, Helvetica, sans-serif;
  }

  .p-panelmenu-panel:first-of-type {
    pointer-events: none;

    a {
      display: block;
      text-align: center;
      margin-top: -1rem
    }

    .p-menuitem-text {
      display: block;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .p-panelmenu-panel {
    .p-panelmenu-header-link {
      border-radius: 0;
    }
  }

  .p-panelmenu-header.p-highlight>.p-panelmenu-header-link,
  .p-panelmenu-header.p-highlight>.p-panelmenu-header-link:hover {
    border-right: 3px solid #9C67CD;
    background-color: #FBF5FE;
  }

  .p-panelmenu-content .p-menuitem a.p-menuitem-link.p-highlight .p-menuitem-text {
    color: #9C67CD;
    font-weight: 700;
  }
}

.p-sidebar-mask {
  @include media('>desktop') {
    display: none;
  }
}

.ods-breadcrumb {
  margin-left: 59px;

  @include media('>desktop') {
    margin-left: 0;
  }

  @include media('<tablet') {
    visibility: hidden;
  }
}

.p-paginator-current {
  cursor: default;
}

.supprimer-danger-button {
  color: $colorVariablesDangerColor;
}


.deleteActionMenu .p-menuitem-link:not(.p-disabled) {

  .p-menuitem-text,
  .p-menuitem-icon {
    color: red;
  }
}