@import 'src/layout/design/colors';

$dialog-box-margin-width: 1rem;
$dialog-box-margin-height: 0.75rem;

@keyframes animate-ellipsis {
  0% {
    color: $colorFills1BasicBasic700;
  }
  50% {
    color: $colorFills1BasicBasic300;
  }
  100% {
    color: $colorFills1BasicBasic700;
  }
}

.DialogBox {
  box-sizing: border-box;
  border-radius: 4px !important;
  & .p-dialog-header {
    padding: $dialog-box-margin-width !important;
    border-bottom: 1px solid $colorFills1BasicBasic300 !important;
    & .p-dialog-title {
      margin: 0 !important;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  &.no-top-separator .p-dialog-header {
    border-bottom-color: transparent !important;
    padding-bottom: 0 !important;
  }
  & .p-dialog-content {
    padding: 10px $dialog-box-margin-width 0 $dialog-box-margin-width !important;
  }
  & .p-dialog-footer {
    padding: 0 $dialog-box-margin-width $dialog-box-margin-height $dialog-box-margin-width !important;
    & .p-button {
      font-size: 12px !important;
      padding: 9px 12px !important;
      height: 36px !important;
    }
    & .confirm-btn {
      color: var(--primary-color-text);
      background-color: $colorVariablesLinkColor;
      border-color: $colorVariablesLinkColor;

      &.danger-color {
        background-color: $colorFills7RedRed700 !important;
        border-color: $colorFills7RedRed700 !important;
      }
      &.p-disabled {
        opacity: 0.4;
        color: var(--primary-color-text);
        background-color: $colorVariablesLinkColor !important;
      }
      &.p-disabled.danger-color {
        background-color: $colorFills7RedRed700 !important;
      }
    }
  }
  & .separator {
    height: 1px;
    background-color: $colorFills1BasicBasic300;
    border: none;
    margin-left: -$dialog-box-margin-width;
    margin-right: -$dialog-box-margin-width;
    margin-bottom: $dialog-box-margin-height;
    margin-top: 0px;
  }
  &.no-bottom-separator .separator {
    background-color: transparent;
  }
  & .title-wrapper {
    position: relative;
    & .loading-title {
      position: absolute;
      top: 0.5rem;
      animation: animate-ellipsis 1.5s ease-in-out infinite;
    }
  }
}
