@import 'src/layout/design/colors';

.customChip {
  font-size: 13px;
  border-radius: 3px;
  background-color: $colorFills6YellowYellow600;
  color: white;
  margin-right: 0.5rem;
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}

.customChipOptional {
  font-size: 13px;
  border-radius: 3px;
  background-color: $colorFills4BlueBlue100;
  color: $colorVariablesTextColorBasic800_;
}
