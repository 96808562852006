@import "../../../layout/design/colors";

.nouvelleSouscriptionWrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: #f4f5f8;
}

.formTitle {
  // font-size: 34px;
  font-size: 27px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-top: 24px;
}

.stepperWrapper {
  text-align: right;
  padding-right: 45px;
  padding-top: 35px;
  justify-content: flex-end;
}

.labelInfoCardClient {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 600;
}

.chevron {
  font-weight: 600;
  font-size: 24px;
  vertical-align: -7px;
}

.textB3 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #19171a;
}

.prestationCardCell {
  vertical-align: middle;
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.famillePrestation {
  font-size: 10px;
  font-family: Open Sans;
  font-weight: 600;
}

.designationPrestation {
  font-size: 0.875rem;
  font-weight: 400;
}

.designationPrestationSubLabel {
  font-size: 12px;
  padding-left: 16px;
  color: $colorVariablesTextColorBasic400_;
}

.titleTotalHT {
  text-align: right;
  padding-right: 16px;
}

.prestationCardCellTotalHT {
  vertical-align: middle;
  text-align: right;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.columnTitlePrestationCard {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #19171a;
}


.uploadButtonCustom {
  font-size: 14px;
  font-weight: 600;

  span.p-button.p-fileupload-choose {
    font-family: Montserrat;
    color: #3688ff;
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 600;
    &:hover{
      color: #3688ff;
      background: none;
      border: none;
    }
    .pi-plus:before {
      font-family: "odsicons";
      content: "\f12c";
      font-size: 24px;
    }
  }
}
