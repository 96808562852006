#indexesTableTab {
    .p-tabview-panels {
        display: none;
    }
}
.indexes-table-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boldLabel{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}