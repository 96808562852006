@import 'src/layout/design/colors.scss';

.action-button {
  & #actionButton > button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
    padding: 0;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    &:focus,
    &:active,
    &:hover {
      box-shadow: 0 0 0 0.2rem rgba($colorVariablesLegacyPrimaryColor, 0.5) !important;
    }
    span.pi.pi-chevron-down {
      width: 1rem;
      height: 1rem;
    }
  }
}
