.p-menubar-end {
	.p-button {

		&,
		&:hover,
		&:focus,
		&:enabled:hover,
		&:enabled:focus {
			background-color: transparent;
			border: none;
			box-shadow: none;
		}
	}

	.layout-terminal-info {
		color: #222222;
	}

	.manage-terminal-button {
		.p-button-label {
			width: 5px;
			flex: 0 0 auto;
		}
	}
}

.p-menubar.ods-headerbar {
	border-radius: 0px;
	border-top: none;
	border-left: none;
	border-right: none;
}

.p-dialog .p-dialog-header {
	border-bottom: 0 none;
	padding: 0 24px 8px 24px !important;
}

.p-dialog .p-dialog-footer {
	border-top: 0 none;
}

.p-confirm-dialog .p-confirm-dialog-icon {
	font-size: 2rem;
	margin-left: 12px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
	margin-left: 1rem;
}

.p-confirm-dialog .p-dialog-content {
	display: flex;
	align-items: center;
}

.accordion-form-container {
	height: 100%;
	padding-right: 24px;

	.accordion-stepper {
		height: 100%;
	}
}

#designation {
	.p-input-icon-left .pi-times {
		top: 30px;
		left: 2px;
		color: #ff5959;
	}
}


#dialogLierOffreId .p-input-icon-left .pi-times {
	margin-top: 11px;
	left: 2px;
}

.p-invalid {
	color: $red;
}

.p-component.p-invalid {
	color: #000
}

.ods-datatable.p-datatable {
	.p-sortable-column-icon {

		&.pi-sort-alt,
		&.pi-sort-amount-up-alt,
		&.pi-sort-amount-up-alt,
		&.pi-sort-amount-down,
		&.pi-sort-amount-down-alt {
			height: 24px;
			width: 24px;
		}
	}
}