#subscriptions-table {
  &.p-datatable .p-datatable-tbody>tr>td {
    padding: 4px 1rem 5px 1rem;
    height: 48px;
  }

  & .p-filter-column .r-select-control {
    min-width: 200px;
    font-weight: normal;
  }

  & .p-filter-column input:focus {
    &::placeholder {
      opacity: 0;
    }
  }
}

.SubscriptionGridSortingPanel {
  .p-dropdown-items-wrapper {
    max-height: 100% !important;
  }

  .p-dropdown-items .p-dropdown-item {
    .p-radiobutton-checked .p-radiobutton-box.p-highlight {
      border-color: #9c67cd;
    }

    &.p-highlight {
      background-color: transparent;
      color: black;
    }

    span {
      margin-left: 4px;
    }
  }
}

.SubscriptionGridSorting {
  .p-dropdown-label {
    font-size: 14px;
    font-weight: 600;
  }
}