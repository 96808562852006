@import 'src/layout/design/colors.scss';

.OffersDetailsPage {
  & .header {
    margin: 1rem 0 0.6rem 0;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    font-weight: bold;
  }

  & .item {
    display: flex;
    color: $colorVariablesSecondaryColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    span {
      line-height: 12px;
    }
    & span:first-of-type {
      display: block;
      width: 13rem;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin-right: 0.5rem;
    }

    & span:not(:first-of-type) {
      display: block;
      font-weight: 400;
    }

    &.noWrap {
      & span:first-of-type {
        white-space: normal;
        line-height: 24px;
      }
    }
  }
}
