@import "../../../layout/design/colors.scss";

.pageContainerForm {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: $colorFills2SkinSkin300;
  overflow: auto;
}

.formHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;


  height: 3.5rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 24px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);
}

.formBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5rem 1rem 0;
  gap: 1rem;
}
