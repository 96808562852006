@import 'src/layout/design/colors';

.SubmitButton {
  @mixin disabled-button {
    opacity: 0.4;
    color: var(--primary-color-text);
  }

  color: var(--primary-color-text);
  border-radius: 6px;

  & .p-button-label {
    font-family: Montserrat;
  }

  &.no-border, &.no-border:focus {
    border-width: 0;
    box-shadow: none;
  }

  &.p-button-sm {
    font-size: 14px;
    font-weight: bold;
    padding: 9px 12px;
    height: 34px;
  }

  &.p-button-md {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 24px;
    height: 41px;
  }

  &.p-button-lg {
    font-size: 16px;
    font-weight: 700;
    padding: 13px 36px;
    height: 50px;
  }

  &:disabled {
    @include disabled-button;
    background-color: $colorVariablesLegacyPrimaryColor;
  }

  &.p-button-primary:hover {
    background-color: rgba($colorVariablesLinkColor, 0.8);
    border-color: rgba($colorVariablesLinkColor, 0.8);
  }

  &.p-button-primary,
  &.p-button-primary:focus,
  &.p-button-primary:active {
    background-color: $colorVariablesLinkColor;
    border-color: $colorVariablesLinkColor;
  }

  &.p-disabled.p-button-primary {
    @include disabled-button;
    background-color: $colorVariablesLinkColor;
  }

  &.p-button-primary-outlined,
  &.p-button-primary-outlined:hover,
  &.p-button-primary-outlined:focus,
  &.p-button-primary-outlined:active {
    background-color: transparent;
    border-color: $colorVariablesLinkColor;
    color: $colorVariablesLinkColor;
  }

  &.p-disabled.p-button-primary-outlined {
    @include disabled-button;
    background-color: transparent;
    color: $colorVariablesLinkColor;
  }

  &.p-button-danger {
    background-color: $colorFills7RedRed700;
    border-color: $colorFills7RedRed700;
  }

  &.p-disabled.p-button-danger {
    @include disabled-button;
    background-color: $colorFills7RedRed700;
  }

  &.p-button-danger-outlined {
    background-color: transparent;
    border-color: $colorFills7RedRed700;
    color: $colorFills7RedRed700;
  }

  &.p-disabled.p-button-danger-outlined {
    @include disabled-button;
    background-color: transparent;
    color: $colorFills7RedRed700;
  }

  &.p-button-secondary,
  &.p-button-secondary:focus,
  &.p-button-secondary:active,
  &.p-button-secondary:hover {
    background-color: $colorFills1BasicBasic400;
    border-color: $colorFills1BasicBasic400;
  }

  &.p-disabled.p-button-secondary {
    @include disabled-button;
    background-color: $colorFills1BasicBasic400;
    border-color: $colorFills1BasicBasic400;
  }

  &.p-button-secondary-outlined,
  &.p-button-secondary-outlined:hover,
  &.p-button-secondary-outlined:focus,
  &.p-button-secondary-outlined:active {
    background-color: transparent;
    border-color: $colorTransparentFill1BasicBasic24;
    color: $colorVariablesTextColorBasic800_;
  }

  &.p-disabled.p-button-secondary-outlined {
    @include disabled-button;
    background-color: transparent;
    color: $colorFills1BasicBasic600;
  }
}
