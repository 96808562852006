@import 'src/layout/design/colors';

.FormFieldGroup {
  & .title {
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: $colorVariablesSecondaryColor;
  }

  & .label {
    display: block !important;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 0.25rem;
    user-select: none;
  }

  & .p-error {
    display: block !important;
    font-size: 0.75rem !important;
    white-space: nowrap;
  }

  & .pi-check {
    color: $colorVariablesSuccessColor;
  }

  & .p-inputtext {
    color: $colorVariablesInputTextColor;
  }

  & .form-field-wrapper {
    &:has(label) {
      height: 4.75rem;
    }

    height: 3.7rem;
  }

  & .p-dropdown.p-invalid.p-component {
    transition: border-color ease-in 0.5s;
  }

  & .form-field-wrapper {
    &.autoHeight {
      &:has(label) {
        height: auto;
      }

      height: auto;
      padding-bottom: 1rem;
      & .p-error {
        white-space: wrap;
      }
    }
  }

  & .p-chips-token {
    $chipsContainer: &;
    margin: 4px 0;

    .p-chips-token-icon {
      &:before {
        content: "\e90b";
        font-size: 14px;
      }
    }

    &.p-highlight {
      background-color: #ededed;
      color: #000;
      border-radius: 16px;
    }
  }
}