@import "src/layout/design/colors";

// ods ovveride
.customLinkButton {
  color: $colorVariablesPrimaryColor !important;
  width: auto !important;
  height: 32px !important;
  padding: 0.5rem !important;
  border: none !important;
  font-family: Arial !important;
  font-size: 12px !important;
}

.listItemWrapper {
  span[class*="p-"] {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    color: black;
  }
}