@import 'src/layout/design/colors';

.contact-table {
  border-bottom: 1px solid $colorVariablesSecondaryColorLight;
  & th.field {
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 1rem;
  }
}
