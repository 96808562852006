.container {
  display: table;
  border-collapse: collapse
}

.progressiveTableHeader {
  display: flex;
  flex-direction: row;
}

.progressiveTableRow {
  display: flex;
  flex-direction: row;
}

.progressiveTableHeaderCell {
  display: flex;
  height: 3rem;
  padding: 0 0.5rem;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border-collapse: collapse;
  border: 1px solid #D8D8D8;
  border-right: none;
}

.progressiveTableRowCell {
  display: flex;
  height: 3rem;
  padding: 0 0.5rem;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border-left: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
}
