@keyframes fade-out{
  0%{
    opacity: 1.0
  }
  100%{
    opacity: 0.0
  }
}

#nouvelle-souscription .accordion-stepper .content {
  background-color: white;
  padding: 0 15px;
  border-radius: 4px;
  opacity: 1;

  #choix-client-souscription {
    margin-top: -47px;
    transition: opacity 0.5s ease-in-out;
  }
}

#documentUploadSub {
  border: 1px solid red;
}
