.resumeTitle {
  width: 47px;
  height: 15px;
  margin: 0 396px 49px 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: var(--black);
}

.ligneTotalTitle {
  height: 19px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #19171a;
}

.ligneTotal {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #19171a;
}
