#prestation-table.p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 1rem 5px 1rem;
}

#splitButtonPrestationsId > button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
  padding: 0;
  border-radius: 5px;
  width: 2rem;
  height: 2rem;

  span.pi.pi-chevron-down {
    width: 16px;
    height: 16px;
  }
}
