@import 'src/layout/design/colors.scss';

.create-new-button {
  width: fit-content;
  color: $colorVariablesLinkColor !important;

  & .p-button-label {
    font-weight: bold;
  }

  &:hover,
  &:focus,
  &:active {
    color: $colorVariablesLinkColor !important;
    background: rgba($colorVariablesLinkColor, 0.1) !important;
    transition: background 0.5s;
  }

  &:focus {
    box-shadow: rgba($colorVariablesLinkColor, 0.5) 0px 0px 0px 3.2px;
    border: rgba($colorVariablesLinkColor, 0.5);
  }

  &.outlined {
    border: 1px solid $colorFills1BasicBasic200;

    & .pi {
      color: $colorFills1BasicBasic400;
    }
  }
}