//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
@import "../components/souscriptions/overrides_souscriptions.scss";
@import "src/layout/design/colors";

body .p-button {
	border: none;
		background-color: inherit;
		border: 1px solid $activated;
	&, &:focus {
		border: none;
		background-color: $activated;
		border: 1px solid $activated;
	}
}

.layout-main {
	padding: 1rem;
}

#dialogCreationPrestationId {
	.p-dialog-content {
		overflow-y: initial;
	}

	.p-dialog-footer {
		button {
			margin: auto 20px;
		}
	}
}

.p-dialog .p-dialog-footer button {
	margin: auto 20px;
}

#dialogLierOffreId{
	.p-dialog-content {
		padding: 8px 0 0 0;
		overflow-y: initial;
	}
}

#dialogLierPrestationId {
	.p-dialog-content {
		padding: 8px 0 0 0;
		overflow-y: initial;
	}
	.pi.pi-info-circle {
		vertical-align: middle;
		font-size: 16px;
		margin-left: 0.5rem;
	}

	small.p-invalid {
		display: block;

	}
	.dropdown-wrapper {
		display: inline-block;
	}

	.dropdown-infobubble {
		position: relative;
	}

	i.pi-info-circle {
		position: absolute;
		top: 16px;
	}
}

#dialogCreerClientId, #dialogEditTeamId {
	.dropdown-infobubble {
		position: relative;
	}
	.dropdown-wrapper {
		display: inline-block;
	}
}

#tableListeClientsId .p-splitbutton-menubutton.p-button-icon-only {
	.pi-chevron-down {
		transform: rotate(0);
	}
}

#dialogCreationPrestationId {
	.file-upload-description {
		line-height: 1.17;
	}
}

.p-dialog .p-field label {
	font-family: Open Sans;
	font-size: 12px;
}

#lesPrestationsId {
	.p-card-body {
		.p-card-subtitle{
			max-width: fit-content;
		}
	}

	.p-radiobutton {
		vertical-align: middle;
		margin-right: 4px;

	  label {
			line-height: 1.17;
		}
	}
}


#nouvelle-offre #designation, #nouvelle-promotion-card, #dialogLierOffreId {
	.pi.pi-check {
		top: 0;
		margin-top: 0;
	}
}

#dialogLierOffreId {
	.pi.pi-check {
		margin-top: -8px;
	}
}

.formulaire-prestation {
	.p-card-header > div{
		padding: 2.5px;
	}
}

.hover-underline {
	&:hover{
		text-decoration: underline;
	}
}

.hover-pointer{
	&:hover{
		cursor: pointer;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.only-accept .p-confirm-dialog-reject{
	display:none;
}

#listePrestationsOptionnelles {
	.p-datatable-thead{
		display:none;
	}
}
.p-selectbutton .p-button {
  background: white;
  color: #101010;
  border: 1px solid rgba(106, 106, 106, 0.24);
}

.p-selectbutton .p-button.p-highlight {
  background: $colorVariablesPrimaryColor;
  border: 1px solid rgba(106, 106, 106, 0.24);
}

.p-selectbutton .p-button.p-highlight:hover {
  background: $colorVariablesPrimaryColor;
  border: 1px solid rgba(106, 106, 106, 0.24);
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: $colorVariablesPrimaryColor;
  border: 1px solid rgba(106, 106, 106, 0.24);
}


.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $colorVariablesPrimaryColor;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background: $colorVariablesPrimaryColor !important;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 50px;
}
.p-inputswitch .p-inputswitch-slider:before {
  border-radius: 50px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $colorVariablesPrimaryColor;
  background: $colorVariablesPrimaryColor;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $colorVariablesPrimaryColor !important;
  background: $colorVariablesPrimaryColor !important;
}

.p-datatable .p-datatable-header {
  background: #ffffff;
  border-width: 0 0 1px 0;
}
